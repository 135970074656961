

























































































import {Component, Watch} from 'vue-property-decorator'
import NotificationsMixin from '@/components/mixins/Notifications'
import rfdc from 'rfdc'
import {mixins} from 'vue-class-component'
import VcSwitch from '@/components/VCSwitch.vue'
import ToolTip from '@/components/custom/ToolTip.vue'
import {settingsStore} from '@/store/modules/settings'
import {copyTextToClipboard} from '@/utils/helpers'

const clone = rfdc({proto: true})

@Component({
  name: 'business-page-access',
  components: {VcSwitch, ToolTip}
})
export default class BusinessPageAccess extends mixins(NotificationsMixin) {
  hint: string = ''
  disabled: boolean = false
  validForm: boolean = false
  accessProtectionActive: boolean = false
  accessProtectionCode: string = ''

  @Watch('accessProtectionActive')
  async onAccessProtectionActiveChanged(after, _) {
    await settingsStore.accessProtectionActiveChanged(after)
  }

  @Watch('accessProtectionCode')
  onAccessProtectionChanged(after, before) {
    this.checkValidatedForm()
    this.disabled = after === settingsStore.accessProtection.code
  }

  async onShareAccessCode() {
    if (this.accessProtectionCode.length > 0) {
      await copyTextToClipboard(this.accessProtectionCode)
      this.showInfo('Business page access password copied to clipboard', 'chain')
    }
  }

  get copyClass() {
    if (!this.validForm) {
      return 'disabled'
    }
    return ''
  }

  checkValidatedForm() {
    const form: any = this.$refs.formPageAccess
    if (form) {
      this.validForm = form.validate()
    }
  }

  private initValues() {
    this.accessProtectionActive = settingsStore.accessProtection.active
    this.accessProtectionCode = settingsStore.accessProtection.code
  }

  async onSave() {
    if (!this.validForm) {
      return
    }
    try {
      this.disabled = true
      await settingsStore.accessProtectionCodeChanged(this.accessProtectionCode)
      this.initValues()
      this.showInfo('Business page access password created successfully')
    } catch (e: any) {
      this.disabled = false
      this.showIssue(e.message)
    }
  }

  onDiscard() {
    this.initValues();
  }

  created() {
    this.initValues()
    this.checkValidatedForm()
  }
}
